<template>
<v-card>
    <v-card-title>
        <span class="headline"> * {{title}} !  </span>
        </v-card-title>   
            <v-spacer></v-spacer>
        <v-card-subtitle v-for="data,i in text " v-bind:key="i">
            <span v-bind:style="{'color':'red'}" >
                {{i+1+'. ' + data }}
            </span>
        </v-card-subtitle>  
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#006400"
                text
                @click="close"
            >
                Cerrar
            </v-btn>
        </v-card-actions>   
    </v-card>
</template>

<script>
export default {
    name: "InfoP", 
    props: ['text', 'title'],
    methods: {
        close(){
            this.$emit('toggle', true)
        }
    },
}
</script>

<style>

</style>